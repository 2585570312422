<template lang="pug">
  .progress-card-container
    .d-flex
      .icon-container.mr-3
        i.icon-style(:class="`icon-${imageIcon}`")
      .text-style
        .steps-info Step {{currentStep}} / {{totalSteps}}
        .step-title {{stepTitle}}
      .chevron-container
        .chevron-style
          i.icon-chevron-left.mr-2.ml-2.cursor-pointer(
            :class="currentStep>1?'chevron-active':'chevron-inactive'",
            @click="handleClick('left')")
          i.icon-chevron-right.cursor-pointer(
            :class="currentStep==totalSteps?'chevron-inactive':'chevron-active'",
            @click="handleClick('right')")
    .progress.progress-bar-container
      .progress-bar.pb(
        role="'progressbar'",
        :aria-valuenow="currentStep",
        aria-valuemin="0",
        :aria-valuemax="totalSteps",
        :style="'width: '+20*currentStep+'%;'")
</template>

<script>
export default {
  name: "ProgressCard",
  props: {
    imageIcon: {
      type: String,
      default: "",
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 5,
    },
    stepTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick(type) {
      if (type === "left") {
        this.$emit("handleLeftClick");
      } else if (type === "right") {
        this.$emit("handleRightClick");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.progress-card-container {
  width: 100%;
}
.icon-container {
  color: $gray-200;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  background-color: $blackpearl;
  padding: 15px;
  flex: 0 1 36px;
}

.icon-style {
  position: relative;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.text-style {
  font-family: "Inter";
  font-style: normal;
  letter-spacing: 0.01em;
  line-height: 132%;
  font-size: 12px;
  flex: 1 1 70%;
  .steps-info {
    font-weight: 500;
    color: $gray-700;
    padding: 2px;
  }
  .step-title {
    font-family: "Hergon Grotesk";
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: $black;
    padding: 2px;
  }
}
.chevron-container {
  flex: 1 1 18%;
  display: flex;
  justify-content: flex-end;
  .chevron-style {
    // padding: 5px;
    margin-top: 5px;
  }
}

.chevron-active {
  color: $gray-900;
}
.chevron-inactive {
  color: $gray-500;
  pointer-events: none;
}

.progress-bar-container {
  width: 100%;
  height: 5px;
  margin: 20px 0 30px 0;
  .pb {
    background-color: $teal-700;
    border-radius: 32px;
  }
}
</style>
